<template>
  <div>
    <v-card flat>
      <v-card-title class="justify-center">
        <span
          class="text-h2"
          style="word-break: keep-all;"
        >
          Saturne <span class="cyan--text accent-2">6.0</span>
        </span>
      </v-card-title>
    </v-card>
    <v-card flat>
      <v-card-title class="justify-center">
        <span
          class="text-h4"
          style="word-break: keep-all;"
        >Plateforme de gestion d'évènements ENSTA Paris</span>
      </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="10">
            <v-card flat>
              <v-card-title>À propos</v-card-title>
              <v-card-text>
                <p class="body-1">
                  Saturne 6.0 est un projet de l'association <a
                    class="no-dec"
                    href="https://data.ensta.fr"
                  >DaTA ENSTA</a> en partenariat avec l'ENSTA Paris afin de proposer une solution de gestion des évènements.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row
      justify="center"
      align="start"
      class="mt-3"
    >
      <v-col
        cols="11"
        sm="5"
        md="4"
        lg="3"
        align-self="center"
      >
        <v-img
          :src="require('../assets/ensta_paris.svg')"
          max-height="100"
          contain
        />
      </v-col>
      <v-col
        cols="11"
        sm="5"
        md="4"
        lg="3"
        align-self="center"
      >
        <v-img
          :src="require('../assets/logo_DaTA.png')"
          max-height="100"
          contain
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style>
.no-dec,
a:link {
  text-decoration: none;
}
</style>
